@import '../../scss/index.scss';
.nosotros {
    background-color: #de2f7f;
    padding-bottom: 12vw;
    position: relative;
    &::before {
        background-color: inherit;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: -13vw;
        left: -20%; // Ajusta según necesites
        transform: rotate(5deg);
        width: 140%; // Cubre exactamente el ancho del elemento .nosotros
    }
    .introSectionBlockWrap {
        display: none;
    }
    .introSectionTextContentWrap {
        display: none;
    }
    .title {
        font-family: $title;
        font-size: 40px;
        color: #fff;
        transform: rotate(-15deg);
    }
    .introContentDesktopWrap {
        align-items: center;
        display: flex;
        margin: auto;
        max-width: 97%;
        padding: 80px 20px;
        transform-origin: top;
        margin-top: -50px;
        .introDesktopLeftCol {
            width: 50%;
            .introContentDesktop {
                transform-origin: top;
                color: #fff;
                font-size: 22px;
                font-weight: 500;
                line-height: 32px;
                margin-top: -4%;
                max-width: 560px;
                transform: rotate(-15deg);
                margin-left: -20px;
            }
            .introMainImageDesktopWrap {
                display: block;
                margin-left: 36px;
                margin-top: 35px;
                max-width: 692px;
                position: relative;
                transform: rotate(-15deg);
                transform-origin: top;
                .introMainImageDesktopInner {
                    filter: drop-shadow(-20px 20px 0 rgba(0, 0, 0, .15));
                    .triangle {
                        transform-origin: top;
                        top: 25px;
                        position: relative;
                        width: 100%;
                        min-height: auto;
                        background: url('../../assets/png/image2.jpg');
                        background-size: cover;
                        -webkit-mask: url('../../assets/png/triangle.png');
                        -webkit-mask-size: contain;
                        -webkit-mask-repeat: no-repeat;
                        -webkit-mask-position: center;
                        transition: opacity 1s ease 0s, top 1s ease 0s;
                        padding: 100% 0 0;
                        animation: float 4s linear 0s infinite alternate;
                        /* Utiliza la propiedad filter */
                    }
                    &::before {
                        background-image: url('../../assets/svg/bars.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        content: "";
                        height: 291px;
                        left: -10%;
                        position: absolute;
                        top: 41%;
                        transform: rotate(14deg);
                        width: 312px;
                    }
                }
            }
            @media screen and( max-width: 820px) {
                .introContentDesktop,
                .introMainImageDesktopWrap {
                    display: none;
                }
            }
        }
        .introDesktopRightCol {
            position: relative;
            text-align: center;
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            display: block;
            width: 50%;
            .introDesktopImageOneWrap {
                filter: drop-shadow(-20px 20px 0 rgba(0, 0, 0, .15));
                .introDesktopImageOne {
                    margin: auto;
                    max-width: 28vw;
                    transform: rotate(18.46deg) translate(-7vw, 3vw);
                    .triangle1 {
                        /* opacity: 0 !important; */
                        transform-origin: top;
                        position: relative;
                        width: 100%;
                        min-height: auto;
                        background: url('../../assets/png/image3.jpg');
                        background-size: cover;
                        -webkit-mask: url('../../assets/png/triangle.png');
                        -webkit-mask-size: contain;
                        -webkit-mask-repeat: no-repeat;
                        -webkit-mask-position: center;
                        padding: 100% 0 0;
                        animation: float 4s linear .5s infinite alternate;
                        transition: opacity 1s ease 0s, top 1s ease 0s;
                    }
                    &::before {
                        background-image: url('../../assets/svg/bars.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        content: "";
                        height: 225px;
                        position: absolute;
                        right: -26%;
                        top: -26%;
                        transform: rotate(166deg);
                        width: 236px;
                    }
                }
            }
            .introDesktopImageTwoWrap {
                filter: drop-shadow(-20px 20px 0 rgba(0, 0, 0, .15));
                .introDesktopImageTwo {
                    margin: auto;
                    max-width: 40vw;
                    transform: rotate(329deg) translate(6vw, -2vw);
                    .triangle2 {
                        background: url('../../assets/png/banner_no_paquete.jpg');
                        background-size: cover;
                        -webkit-mask: url('../../assets/png/triangle.png');
                        -webkit-mask-size: contain;
                        -webkit-mask-repeat: no-repeat;
                        -webkit-mask-position: center;
                        transition: opacity 1s ease 0s, top 1s ease 0s;
                        padding: 100% 0 0;
                        animation: floatAlt 4s linear 1s infinite alternate;
                        /* Utiliza la propiedad filter */
                    }
                    &::before {
                        background-image: url('../../assets/svg/bars.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        bottom: 28%;
                        content: "";
                        height: 273px;
                        left: 14%;
                        position: absolute;
                        transform: rotate(257deg);
                        width: 262px;
                    }
                }
            }
            @media screen and( max-width: 820px) {
                .introDesktopImageOneWrap,
                .introDesktopImageTwo {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 819px) {
    .nosotros {
        padding-bottom: 12vw;
        position: relative;
        &::before {
            background-color: inherit;
            content: "";
            display: block;
            height: 100%;
            left: -20%;
            position: absolute;
            top: -13vw;
            transform: rotate(5deg);
            width: 150%;
        }
        .title {
            margin-top: 20px;
        }
        .introSectionBlockWrap {
            margin: auto;
            max-width: 600px;
            position: relative;
            display: block;
            .introSectionImagesWrap {
                margin-top: -1vw;
                align-items: end;
                display: flex;
                gap: 20px;
                justify-content: end;
                position: relative;
                width: 134%;
                box-sizing: border-box;
                .introSectionAdditionalImageOneWrap {
                    bottom: 0;
                    filter: drop-shadow(10px 10px 0 rgba(0, 0, 0, .15));
                    left: -33px;
                    position: absolute;
                    transform: rotate(28deg);
                    width: 43%;
                    .triangle2 {
                        background: url('../../assets/png/banner_no_paquete.jpg');
                        background-size: cover;
                        -webkit-mask: url('../../assets/png/triangle.png');
                        -webkit-mask-size: contain;
                        -webkit-mask-repeat: no-repeat;
                        -webkit-mask-position: center;
                        transition: opacity 1s ease 0s, top 1s ease 0s;
                        padding: 100% 0 0;
                        animation: floatAlt 4s linear 1s infinite alternate;
                        /* Utiliza la propiedad filter */
                    }
                }
                .introSectionMainImageWrap {
                    filter: drop-shadow(-10px 10px 0 rgba(0, 0, 0, .15));
                    position: relative;
                    transform: rotate(350deg) translate(-59px, -68px);
                    width: 59%;
                    .triangle {
                        transform-origin: top;
                        top: 0;
                        position: relative;
                        width: 100%;
                        min-height: auto;
                        background: url('../../assets/png/image2.jpg');
                        background-size: cover;
                        -webkit-mask: url('../../assets/png/triangle.png');
                        -webkit-mask-size: contain;
                        -webkit-mask-repeat: no-repeat;
                        -webkit-mask-position: center;
                        transition: opacity 1s ease 0s, top 1s ease 0s;
                        padding: 100% 0 0;
                        animation: float 4s linear 0s infinite alternate;
                        /* Utiliza la propiedad filter */
                    }
                    &::before {
                        background-image: url('../../assets/svg/bars.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        content: "";
                        height: 226px;
                        left: -106px;
                        position: absolute;
                        top: -116px;
                        width: 232px;
                    }
                }
            }
        }
        .introSectionTextContentWrap {
            margin-bottom: 19vw;
            margin-left: 20px;
            max-width: 300px;
            padding: 0 22px;
            width: 66%;
            display: block;
            .introSectionTextContent {
                color: #fff;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                transform: rotate(-8.31deg);
                p {
                    letter-spacing: var(--baseLetterSpacing);
                    line-height: 1.20;
                    margin: 0 0 30px;
                }
                .introSectionAdditionalImageTwoWrap {
                    filter: drop-shadow(-10px 10px 0 rgba(0, 0, 0, .15));
                    left: 93%;
                    position: absolute;
                    top: 0;
                    transform: rotate(19deg);
                    width: 88%;
                    &::before {
                        background-image: url('../../assets/svg/bars.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        bottom: -87px;
                        content: "";
                        height: 122px;
                        left: 42px;
                        position: absolute;
                        transform: rotate(351deg);
                        width: 128px;
                    }
                    .triangle1 {
                        /* opacity: 0 !important; */
                        transform-origin: top;
                        position: relative;
                        width: 100%;
                        min-height: auto;
                        background: url('../../assets/png/image3.jpg');
                        background-size: cover;
                        -webkit-mask: url('../../assets/png/triangle.png');
                        -webkit-mask-size: contain;
                        -webkit-mask-repeat: no-repeat;
                        -webkit-mask-position: center;
                        padding: 100% 0 0;
                        animation: float 4s linear .5s infinite alternate;
                        transition: opacity 1s ease 0s, top 1s ease 0s;
                    }
                }
            }
        }
    }
}

@keyframes float {
    0% {
        transform: translatey(0) rotate(0deg);
    }
    50% {
        transform: translatey(-20px) rotate(6deg);
    }
    100% {
        transform: translatey(0) rotate(0deg);
    }
}

@keyframes floatAlt {
    0% {
        transform: translatey(0) rotate(0deg);
    }
    50% {
        transform: translatey(-20px) rotate(-6deg);
    }
    100% {
        transform: translatey(0) rotate(0deg);
    }
}