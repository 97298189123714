@import '../../scss/index.scss';
.menuMeta {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    .flyoutWrap {
        bottom: 0;
        left: 100%;
        padding-top: 100px;
        width: 50%;
        background: #703f9b;
        display: block;
        overflow: auto;
        position: fixed;
        top: 0;
        transition: transform .3s ease-in-out;
        z-index: 20;
        overflow-x: hidden;
        .menuWrapper {
            display: block;
            .mainMenu {
                position: relative;
                li {
                    float: left;
                    font-weight: 700;
                    line-height: 1.5;
                    list-style-type: none;
                    text-align: center;
                    width: 100%;
                    position: relative;
                    display: list-item;
                    cursor: pointer;
                    a {
                        display: block;
                        font-family: var(--primaryFont);
                        font-size: 1.600rem;
                        font-weight: 700;
                        padding: 4px 20px;
                        text-shadow: 0 4px 12px rgba(0, 0, 0, .4);
                        text-transform: uppercase;
                        transform: rotate(-5.89deg);
                        outline: 0;
                        color: #fff;
                        text-decoration: none;
                        background-color: transparent;
                        font-family: $subtitlebold;
                        &:hover {
                            color: #ff6900;
                        }
                    }
                    @media screen and( max-width: 785px) {
                        a {
                            font-size: 1.000rem;
                        }
                    }
                }
            }
            ul {
                box-sizing: border-box;
                display: block;
                list-style-type: disc;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                padding-inline-start: 40px;
            }
        }
        .socialsMenu {
            clear: both;
            display: flex;
            gap: 6px;
            justify-content: center;
            position: relative;
            top: 10px;
            transform: rotate(-5.89deg);
            a {
                color: #fff;
                text-decoration: none;
                outline: 0;
                background-color: transparent;
                svg {
                    fill: #fff;
                    ;
                    color: #fff;
                    font-size: 34px;
                    transition: color .3s;
                    overflow: visible;
                    box-sizing: content-box;
                    display: var(--fa-display, inline-block);
                    height: 1em;
                    overflow-clip-margin: content-box;
                    &:hover {
                        fill: #ff6900;
                    }
                }
            }
        }
        @media screen and( max-width: 785px) {
            width: 100%;
        }
    }
}

.active {
    transform: translateX(-100%);
    overflow-y: hidden !important;
}