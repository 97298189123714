@import '../../scss/index.scss';
.eventItem {
    filter: drop-shadow(20px 20px 0 rgba(0, 0, 0, .15));
    max-height: 441px;
    max-width: 260px;
    padding-top: 30px;
    height: calc(100vw - 70px);
    /*  padding: 15px 24px 24px 16px;
    position: relative; */
    .eventBgShape {
        -webkit-mask-image: url('../../assets/svg/opciones.svg');
        -webkit-mask-position: top;
        background-color: #38b2e0;
        height: 100%;
        width: 100%;
        left: 0;
        -webkit-mask-image: url('../../assets/svg/opciones.svg');
        -webkit-mask-position: left;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        position: absolute;
        top: 0;
        width: 110%;
    }
    .eventItemInner {
        display: flex;
        flex-direction: column;
        padding: 0px 20px;
        height: 100%;
        position: relative;
        .eventItemImgWrap {
            box-sizing: border-box;
            display: block;
            img,
            svg {
                vertical-align: middle;
            }
            .imageDesc {
                height: 20%;
                max-height: 20%;
                width: auto;
            }
            img {
                overflow-clip-margin: content-box;
                overflow: clip;
            }
            h3 {
                font-size: 1.875rem;
                font-weight: 900;
                line-height: 1.3;
                text-transform: uppercase;
                font-family: $title;
                margin-top: 10px;
                text-shadow: 0 1px 4px rgba(79, 51, 102, .7);
            }
            span {
                color: #fff;
                font-size: .625rem;
                font-weight: lighter;
                line-height: 1.8;
                text-transform: uppercase;
                font-family: $subtitle;
                text-shadow: 0 1px 4px rgba(79, 51, 102, .7);
            }
        }
    }
    @media screen and( max-width: $media-breackpoint-up-sm) {
        max-height: 481px;
        max-width: 190px;
        height: calc(100vw - 70px);
        padding: 15px 0px 10px 0px;
        position: relative;
        .eventItemInner {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            padding: 0px 10px;
            height: 100%;
            position: relative;
            .eventItemImgWrap {
                box-sizing: border-box;
                display: block;
                img,
                svg {
                    vertical-align: middle;
                }
                .imageDesc {
                    height: 13%;
                    max-height: 13%;
                    width: auto;
                }
                h3 {
                    font-size: 1.175rem;
                }
                span {
                    color: #fff;
                    font-size: .625rem;
                    font-weight: lighter;
                    line-height: 1.8;
                    text-transform: uppercase;
                    font-family: $subtitle;
                }
            }
        }
    }
}