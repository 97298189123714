@import '../../scss/index.scss';
.footerWrap {
    background: #42225c;
    border-top: 1px solid var(--borderColour);
    color: #fff;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    .contentinfo {
        clear: both;
        margin: 0 auto;
        max-width: var(--wrapperWidth);
        max-width: var(--wideWrapperWidth);
        min-width: 20px;
        padding: 0 20px;
        position: relative;
        .subscribeCTAWrapper {
            background-color: #42225c;
            border-radius: 20px;
            color: #fff;
            padding: 30px;
            position: relative;
            text-align: center;
            top: -40px;
            z-index: 2;
            h2 {
                font-size: 2.525rem;
                line-height: 1;
                margin-bottom: 18px;
                font-family: $subtitlebold;
                text-transform: uppercase;
            }
        }
        .subscribeCTADescription {
            color: #fff;
            font-size: .875rem;
            font-weight: 900;
            line-height: 1.7;
            margin-bottom: 22px;
            text-align: center;
            text-transform: uppercase;
            font-family: $subtitle;
        }
        .copyRightRow {
            width: 100%;
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 24px;
            box-sizing: border-box;
            margin: 0;
            .copyRightCol {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 12px;
                span {
                    font-family: $subtitle;
                }
            }
        }
        .logo {
            img {
                display: flex;
                width: 100px;
                height: auto;
                margin-bottom: 80px;
            }
        }
        @media screen and( max-width: 517px) {
            .copyRightRow {
                justify-content: center;
                align-items: center;
                text-align: center;
            }
            .logo {
                margin-top: 10px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        @media screen and( max-width: 385px) {
            .slideOverlay {
                .overlayContent {
                    .image {
                        width: 250px;
                        height: 250px;
                    }
                }
            }
        }
    }
    .fixedButtonsWrap {
        bottom: 20px;
        left: 50%;
        max-width: 250px;
        position: fixed;
        transform: translateX(-50%);
        width: 100%;
        z-index: 19;
        box-sizing: border-box;
        margin: 0;
        .fixedButtons {
            align-items: center;
            background-color: #fff;
            border-radius: 80px;
            box-shadow: 0 10px 0 rgba(0, 0, 0, .15);
            display: flex;
            gap: 5px;
            justify-content: center;
            padding: 11px;
            .fixedButton {
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                font-size: .7000rem;
                text-transform: uppercase;
                color: #025144;
                img {
                    width: 20px;
                    margin-right: 10px;
                }
                span {
                    font-family: $subtitlebold;
                }
            }
        }
    }
}