@import '../../scss/index.scss';
.includedItemleft {
    max-width: 500px;
    width: 100%;
    .includedItemHeadleft {
        background: url('../../assets/svg/izquierda.svg');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        height: 332px;
        margin: 50px auto 24px;
        position: relative;
        text-align: center;
        .itemLogo {
            img {
                height: auto;
                left: 45%;
                position: absolute;
                top: 0;
                transform: translateX(-100%);
                width: 120px;
                z-index: 10;
                max-width: 100%;
                vertical-align: middle;
                aspect-ratio: auto 496 / 393;
                overflow-clip-margin: content-box;
                overflow: clip;
            }
        }
        .itemHeadImageleft {
            padding-top: 32px;
            .itemHeadImageWrapleft {
                text-align: center;
                img {
                    height: 249px;
                    position: relative;
                    top: 15px;
                    object-fit: contain;
                    width: 80%;
                    max-width: 100%;
                    vertical-align: middle;
                    border-style: none;
                    overflow-clip-margin: content-box;
                    overflow: clip;
                }
            }
        }
        .includedItemLabelleft {
            background-color: #703f9b;
            border-radius: 50px;
            color: #fff;
            display: inline-block;
            font-size: 1.125rem;
            font-weight: 700;
            letter-spacing: .1em;
            line-height: 1.3;
            padding: 16px 32px;
            position: relative;
            text-align: center;
            text-transform: uppercase;
            top: -10px;
            transform: rotate(5.6deg);
            font-family: $subtitlebold;
        }
    }
    .itemDescriptionleft {
        color: #fff;
        display: block;
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 105%;
        margin: 0 auto;
        max-width: 350px;
        text-align: center;
        text-shadow: 0 1px 4px rgba(79, 51, 102, .7);
        transform: rotate(5.6deg);
    }
}

@media screen and( max-width: 1001) {
    .includedItemleft {
        max-width: 100%;
    }
}

@media screen and( max-width: $media-breackpoint-up-sm) {
    .includedItemleft {
        max-width: 300px;
        width: 100%;
        .includedItemHeadleft {
            height: 240px;
            margin: 50px auto 24px;
            position: relative;
            text-align: center;
            .itemHeadImageleft {
                max-width: 100%;
                .itemHeadImageWrapleft {
                    text-align: center;
                    img {
                        top: -10px;
                        height: 210px;
                        object-fit: contain;
                        width: 80%;
                    }
                }
            }
            .includedItemLabelleft {
                top: -40px;
            }
        }
    }
}