@import '../../scss/index.scss';
.upcomingEventsSection {
    background: #0397cf;
    color: #fff;
    margin-bottom: -60px;
    padding-bottom: calc(18vw + 60px);
    position: relative;
    &::before {
        background-color: inherit;
        content: "";
        display: block;
        height: 1100px;
        left: -50%;
        position: absolute;
        top: -8vw;
        transform: rotate(8deg);
        width: 190%;
    }
    .upcomingEventsSection,
    .upcomingEventsSectionTitleWrap {
        padding: 0;
        top: -17vw;
        transform: rotate(8deg);
        top: -9vw;
    }
    .marqueeWrap {
        height: 35px;
        max-width: 100%;
        padding-top: 18px;
        position: relative;
        top: -80px;
        width: 100vw;
        .h2 {
            display: block;
            font-size: 1.5em;
            margin-block-start: 0.83em;
            margin-block-end: 0.83em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-weight: bold;
        }
        .upcomingEventsSectionTitle {
            animation-duration: 101s;
            background-color: #0397cf;
            color: #fff;
            font-family: var(--primaryFont);
            font-size: 2.1875rem;
            font-weight: 900;
            margin-bottom: 0;
            text-transform: uppercase;
            padding-bottom: 30px;
        }
        .marqueeTrack {
            animation: marquee 150s linear infinite;
            position: absolute;
            white-space: nowrap;
            will-change: transform;
        }
    }
    .tourDatesList,
    .wrapperWidth {
        .row {
            margin-top: 50px;
            width: 100%;
            .col-xl-4,
            .col-md-6,
            .col-sm-12 {
                display: flex;
                justify-content: center;
                .eventsGrid {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    grid-template-columns: repeat(3, 1fr);
                    max-width: 1300px;
                }
            }
        }
        max-width: 1300px;
        padding: 0 20px;
        /* .upcomingEventsSection,
        .eventsGrid {
            margin-top: 50px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            grid-template-columns: repeat(3, 1fr);
            max-width: 1300px;
        } */
    }
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}