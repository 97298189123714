@import '../../scss/index.scss';
.galleryBlock {
    background-color: #f4700f;
    padding-bottom: 25vw;
    position: relative;
    &::before {
        background-color: inherit;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        right: -50%;
        top: -9vw;
        transform: rotate(-6deg);
        width: 150%;
    }
    .galleryBlockContent {
        position: relative;
        .galleryBlockTitleWrap {
            margin-bottom: 40px;
            margin-top: -5%;
            padding: 0;
            top: -7vw;
            position: relative;
            transform: rotate(-6deg);
            .marqueeTrack {
                animation: marquee 32s linear infinite;
                position: absolute;
                white-space: nowrap;
                will-change: transform;
            }
            .galleryBlockTitle {
                padding-top: 10px;
                padding-bottom: 10px;
                animation-duration: 80s;
                color: #fff;
                font-family: var(--primaryFont);
                font-size: 2.1875rem;
                font-weight: 900;
                text-transform: uppercase;
            }
        }
        .galleryGridWrap {
            position: relative;
            .galleryGrid {
                padding-top: 50px;
                grid-template-columns: repeat(4, 1fr);
                max-width: 80%;
                display: grid;
                gap: 3%;
                left: 50%;
                margin-bottom: 12%;
                max-width: 80%;
                position: relative;
                transform: translateX(-50%) rotate(-6deg);
                width: 123%;
            }
            @media screen and( max-width: $media-breackpoint-up-xl) {
                .galleryGrid {
                    grid-template-columns: repeat(3, 1fr);
                    max-width: 600px;
                }
            }
            @media screen and( max-width: 820px) {
                .introContentDesktop,
                .introMainImageDesktopWrap {
                    display: none;
                }
            }
            @media screen and( max-width: $media-breackpoint-up-sm) {
                .galleryGrid {
                    max-width: 350px;
                }
            }
        }
    }
}

.overlay {
    z-index: 500;
    position: fixed;
    /* Cambiado a position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    /* Fondo oscuro semi-transparente */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 1;
    /* Cambiado a opacity: 0; */
    /* Inicialmente invisible */
    transition: opacity 0.3s ease;
    /* Evitar interacción con los elementos dentro del overlay */
    overflow-y: auto;
    /* Agregado overflow-y: auto; */
    .slideOverlay {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .overlayContent {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .image {
                filter: drop-shadow(8px 6px 0 rgba(0, 0, 0, .15));
                width: 70vh;
                /* Ajusta el tamaño del cuadrado según sea necesario */
                height: 70vh;
                /* Ajusta el tamaño del cuadrado según sea necesario */
                background-size: cover;
                background-position: center;
            }
            p {
                color: white;
                font-size: 20px;
                text-align: center;
                font-family: $subtitlebold;
                text-shadow: 0 4px 12px rgba(0, 0, 0, .5);
            }
        }
        svg {
            z-index: 101;
            margin: 10px;
            fill: white;
            cursor: pointer;
            /* Color blanco para el SVG */
            width: 24px;
            /* Tamaño del SVG */
            height: 24px;
            /* Tamaño del SVG */
        }
    }
    .buttonClose {
        position: absolute;
        top: 10px;
        /* Ajusta según necesites */
        right: 10px;
        /* Ajusta según necesites */
        padding: 5px;
        /* Ajusta según necesites */
        cursor: pointer !important;
    }
    .buttonClose svg {
        fill: white;
        cursor: pointer;
        /* Color blanco para el SVG */
        width: 24px;
        /* Tamaño del SVG */
        height: 24px;
        /* Tamaño del SVG */
    }
    @media screen and( max-width: $media-breackpoint-up-md) {
        .slideOverlay {
            .overlayContent {
                .image {
                    width: 300px;
                    height: 300px;
                }
            }
        }
    }
    @media screen and( max-width: 385px) {
        .slideOverlay {
            .overlayContent {
                .image {
                    width: 250px;
                    height: 250px;
                }
            }
        }
    }
}

@media screen and( max-width: $media-breackpoint-up-xl) {
    .galleryBlock {
        padding-bottom: 30vw;
    }
}

@media screen and( max-width: 727px) {
    .galleryBlock {
        padding-bottom: 35vw;
    }
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}