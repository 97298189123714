@import '../../scss/index.scss';
.banner {
    background-color: #00A0DD;
    top: 0;
    height: auto;
    width: 100%;
    margin: 0;
    background: url('../../assets/png/fondoBanner.webp') top left no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
    max-width: 100%;
    .animacion {
        padding-top: 180px !important;
        overflow: hidden;
        text-align: center;
        padding: 5% auto;
        margin: auto;
        max-width: 950px;
        animation: tamano 2s ease forwards;
        transform: rotate(-5.89deg);
        height: 320px;
        h1 {
            opacity: 1;
            font-size: 1.875rem;
            font-weight: 700;
            line-height: 105%;
            text-align: center;
            text-shadow: 0 4px 12px rgba(0, 0, 0, .5);
            text-transform: uppercase;
            font-family: $subtitlebold;
            color: white;
            &:nth-child(1) {
                animation: aparecer 2s ease forwards;
            }
            &:nth-child(2) {
                animation: aparecer 2s ease forwards;
                animation-delay: .5s; // Delay para la segunda línea
            }
            span {
                display: inline-block;
                opacity: 0;
                animation: aparecerSpan 0.5s ease forwards;
                margin: 0 3px;
            }
            span:nth-child(1) {
                animation-name: rebote1;
            }
            span:nth-child(2) {
                animation-name: rebote2;
                animation-delay: 0.2s; // Delay para el segundo span
            }
            span:nth-child(3) {
                animation-name: rebote3;
                animation-delay: 0.4s; // Delay para el tercer span
            }
            span:nth-child(4) {
                animation-name: rebote4;
                animation-delay: 0.6s; // Delay para el cuarto span
            }
            span:nth-child(5) {
                animation-name: rebote5;
                animation-delay: 0.8s; // Delay para el quinto span
            }
            span:nth-child(6) {
                animation-name: rebote6;
                animation-delay: 1s; // Delay para el sexto span
            }
            span:nth-child(7) {
                animation-name: rebote7;
                animation-delay: 1.2s; // Delay para el séptimo span
            }
            span:nth-child(8) {
                animation-name: rebote8;
                animation-delay: 1.4s; // Delay para el octavo span
            }
            span:nth-child(9) {
                animation-name: rebote9;
                animation-delay: 1.6s; // Delay para el noveno span
            }
            span:nth-child(10) {
                animation-name: rebote10;
                animation-delay: 1.8s; // Delay para el décimo span
            }
        }
    }
    @media screen and( max-width: $media-breackpoint-up-lg) {
        .animacion {
            height: 370px;
        }
    }
    @media screen and( max-width: $media-breackpoint-up-md) {
        .animacion {
            padding-top: 160px !important;
            height: 250px;
        }
    }
    img {
        border-radius: 50px;
        height: auto;
        object-fit: cover;
        object-position: center;
        transform: rotate(-5.89deg);
        width: 100%;
        opacity: 0; // Inicialmente oculta la imagen
        animation: aparecerImagen 0.5s ease forwards 1.6s;
    }
}


/* @media screen and( max-width: $media-breackpoint-up-xl) {
    .banner {
        height: 60vh;
    }
} */

@keyframes aparecerImagen {
    0% {
        opacity: 0; // Opacidad inicial 0
    }
    100% {
        opacity: 1; // Opacidad final 1
    }
}

@keyframes tamano {
    0% {
        max-width: 350px; // Tamaño inicial
    }
    100% {
        max-width: 950px; // Tamaño máximo
    }
}

@keyframes aparecer {
    0% {
        font-size: 20px; // Tamaño inicial
    }
    100% {
        font-size: 50px; // Tamaño máximo
    }
}

@media screen and( max-width: $media-breackpoint-up-md) {
    @keyframes tamano {
        0% {
            max-width: 350px; // Tamaño inicial
        }
        100% {
            max-width: 400px; // Tamaño máximo
        }
    }
    @keyframes aparecer {
        0% {
            font-size: 20px; // Tamaño inicial
        }
        100% {
            font-size: 25px; // Tamaño máximo
        }
    }
}

@media screen and( max-width: $media-breackpoint-up-xs) {
    @keyframes aparecer {
        0% {
            font-size: 18px; // Tamaño inicial
        }
        100% {
            font-size: 22px; // Tamaño máximo
        }
    }
}

@keyframes aparecerSpan {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes rebote1 {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes rebote2 {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes rebote3 {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes rebote4 {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes rebote5 {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes rebote6 {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes rebote7 {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes rebote8 {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes rebote9 {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes rebote10 {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}