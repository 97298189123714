.navbar_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    left: 0;
    position: absolute;
    top: 0;
    transition: all .5s ease-in-out;
    width: 100%;
    z-index: 400;
    .button_container {
        cursor: pointer;
        text-decoration: none;
        color: white;
        background-color: #dc282e;
        font-size: .75rem;
        font-weight: 900;
        line-height: 1;
        -webkit-mask-image: url('../../assets/svg/button.svg');
        -webkit-mask-position: left;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        padding: 20px 18px 20px 20px;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
            margin-left: 10px;
            width: 8px;
        }
    }
    .button_container:hover svg {
        fill: #830b0f !important;
        color: #830b0f !important;
        /* Cambia el color del SVG al hacer hover */
    }
    .logo {
        max-width: 143px;
        line-height: 1;
        padding: 20px 0;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        img {
            max-width: 20vw;
            width: 100%;
            overflow-clip-margin: content-box;
            overflow: clip;
        }
    }
    .menuTrigger {
        align-items: center;
        background-color: #cbdb2a;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        line-height: 1;
        padding: 14px 8px;
        transition: background-color .5s;
        border: none;
        .menuBar {
            background-color: #fff;
            border-radius: 60px;
            display: block;
            height: 4px;
            position: relative;
            transition: transform .3s;
            width: 26px;
        }
        .active {
            &:first-child {
                top: 4px;
                transform: rotate(45deg);
            }
            &:last-child {
                bottom: 4px;
                transform: rotate(-45deg);
            }
        }
        &:hover {
            background-color: #919C1A;
        }
    }
}

.scrolled {
    background-color: #703F9B;
    position: fixed;
}