@import '../../scss/index.scss';
.testimonialBlock {
    position: relative;
    align-items: center;
    .testimonialContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .galleryShape {
        filter: drop-shadow(8px 6px 0 rgba(0, 0, 0, .15));
        .image {
            border: 8px solid #fff;
            width: 200px;
            height: 200px;
            border-radius: 50%;
        }
    }
    .includedItemLabelleft {
        filter: drop-shadow(8px 6px 0 rgba(0, 0, 0, .15));
        background-color: #42225C;
        border-radius: 50px;
        color: #fff;
        display: inline-block;
        font-size: 1.105rem;
        font-weight: 700;
        letter-spacing: .1em;
        line-height: 1.3;
        padding: 16px 32px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        top: -20px;
        font-family: $title;
    }
    .itemDescriptionleft {
        color: #fff;
        display: block;
        font-size: 0.85rem;
        font-weight: 700;
        line-height: 105%;
        margin: 0 auto;
        max-width: 350px;
        text-align: center;
        text-shadow: 0 1px 4px rgba(0, 0, 0, .15);
        margin-top: -20px;
        font-family: $subtitle;
        ;
    }
}