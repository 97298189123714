@import '../../scss/index.scss';
.compra_seccion {
    background-color: #703f9b;
    position: relative;
    &::before {
        background-color: inherit;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        right: -50%;
        top: -10vw;
        transform: rotate(-6deg);
        width: 150%;
    }
    .marqueeWrap {
        height: 35px;
        max-width: 100%;
        padding-top: 18px;
        top: -74px;
        width: 100vw;
        @media screen and (max-width: $media-breackpoint-up-sm) {
            top: -44px;
        }
    }
    .testimonialsSectionTitleWrap {
        margin-bottom: 7vw;
        margin-top: -5%;
        position: relative;
        transform: rotate(-6deg);
        height: 50px;
        .testimonialSectionTitle {
            animation-duration: 104s;
            color: #fff;
            font-family: var(--primaryFont);
            font-size: 2.1875rem;
            font-weight: 900;
            text-transform: uppercase;
        }
        .marqueeTrack {
            animation: marquee 200s linear infinite;
            position: absolute;
            white-space: nowrap;
            will-change: transform;
        }
    }
    .testimonialBlocks {
        padding: 0 5% 10%;
        display: grid;
        grid-template-columns: repeat(5, 1fr); // 5 columnas en pantallas grandes
        justify-content: center; // Centrar los elementos horizontalmente
        grid-gap: 20px; // Espacio entre elementos
        .testimonialBlock {
            margin-bottom: 20px; // Espacio entre elementos
        }
        @media screen and (max-width: 1200px) {
            grid-template-columns: repeat(2, 1fr); // 2 columnas en pantallas medianas
        }
        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr; // 1 columna en pantallas pequeñas
        }
    }
}