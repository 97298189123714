@import '../../scss/index.scss';
.startAnimation {
    left: 0 !important;
    opacity: 1 !important;
    position: relative;
    transition: opacity 1s ease .5s, left 1s ease .5s;
    transform: rotateY(345deg);
}

.galleryGridItem {
    filter: drop-shadow(8px 6px 0 rgba(0, 0, 0, .15));
    perspective: 575px;
    transform-style: preserve-3d;
    display: flex;
    flex-direction: column; // Para colocar el texto debajo de la imagen
    .galleryShape {
        border-radius: 20px;
        height: 0;
        overflow: hidden;
        position: relative;
        padding-top: 114.38%;
        transform: rotateY(345deg);
        transition: transform 0.3s ease;
        &:hover {
            transform: rotateY(365deg); // Rotación ligeramente diferente al hacer hover
        }
        img {
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
            overflow: hidden;
        }
    }
    .galleryShape2 {
        border-radius: 20px;
        height: 0;
        overflow: hidden;
        padding-top: 114.38%;
        position: relative;
        transform: rotateY(20deg);
        flex: 1;
        transition: transform 0.3s ease;
        &:hover {
            transform: rotateY(0deg); // Rotación ligeramente diferente al hacer hover
        }
        img {
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
            overflow: hidden;
        }
    }
    p {
        color: white;
        font-size: 20px;
        text-align: center;
        font-family: $subtitlebold;
        text-shadow: 0 4px 12px rgba(0, 0, 0, .8);
        position: absolute;
        top: 80%; // Centrar verticalmente
        left: 50%; // Centrar horizontalmente
        transform: translate(-50%, -50%) rotateY(0deg); // Centrar y rotar
        pointer-events: none;
        @media screen and( max-width: $media-breackpoint-up-md) {
            top: 70%;
            font-size: 16px;
        }
    }
}