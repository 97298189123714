@font-face {
    font-family: 'Code Pro Black';
    src: url('../fonts/Code Pro Black.otf') format('truetype');
}

@font-face {
    font-family: 'Walrus-Bold';
    src: url('../fonts/Walrus-Bold.otf') format('truetype');
}

@font-face {
    font-family: 'Mada-SemiBold';
    src: url('../fonts/Mada-SemiBold.ttf') format('truetype');
}

body {
    margin: 0;
    padding: 0;
}

$font-light: #fff;
$font-grey: #a2a2a2;
$font-dark: #000;
$background-light: #fff;
$background-grey: #f0f2f5;
$background-grey-light: #fafafa;
$background-dark: #1f1f1f;
$background-success: #84284c1f;
$background-error: #ff00001f;
$border-grey: #484848;
$border-dark: #000;
$action: #0096f6;
$danger: #ed4956;
$success: #84b84c;
$happy: #ffc300;
$corporativo: #ec3c52;
$rojo: #ec3c52;
$naranja:#f06e10;
$amarillo:#f7ad3b;
//Fuentes
$title:'Code Pro Black';
$subtitlebold: 'Walrus-Bold'; //Responsive up size
$subtitle: 'Mada-SemiBold';
$media-breackpoint-up-xs: 320px;
$media-breackpoint-up-sm: 576px;
$media-breackpoint-up-md: 686px;
$media-breackpoint-up-lg: 992px;
$media-breackpoint-up-xl: 1024px;