@import '../../scss/index.scss';
.includedItem {
    max-width: 500px;
    width: 100%;
    .includedItemHead {
        background: url('../../assets/svg/derecha.svg');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        height: 332px;
        margin: 50px auto 24px;
        position: relative;
        text-align: center;
        .itemLogo {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            img {
                height: auto;
                left: 45%;
                position: absolute;
                top: 0;
                transform: translateX(-100%);
                width: 120px;
                z-index: 10;
                max-width: 100%;
                vertical-align: middle;
                aspect-ratio: auto 484 / 533;
                overflow-clip-margin: content-box;
                overflow: clip;
            }
        }
        .itemHeadImage {
            padding-top: 32px;
            .itemHeadImageWrap {
                text-align: center;
                img {
                    height: 249px;
                    position: relative;
                    top: 15px;
                    object-fit: contain;
                    width: 80%;
                    max-width: 100%;
                    vertical-align: middle;
                    border-style: none;
                    overflow-clip-margin: content-box;
                    overflow: clip;
                }
            }
        }
        .includedItemLabel {
            top: -10px;
            background-color: #de2f7f;
            transform: rotate(-5.6deg);
            border-radius: 50px;
            color: #fff;
            display: inline-block;
            font-size: 1.125rem;
            font-weight: 700;
            letter-spacing: .1em;
            line-height: 1.3;
            padding: 16px 32px;
            position: relative;
            text-align: center;
            text-transform: uppercase;
            font-family: $subtitlebold;
        }
    }
    .itemDescription {
        color: #fff;
        display: block;
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 105%;
        margin: 0 auto;
        max-width: 350px;
        text-align: center;
        text-shadow: 0 1px 4px rgba(79, 51, 102, .7);
        transform: rotate(-5.6deg);
    }
}

@media screen and( max-width: 1001) {
    .includedItem {
        max-width: 100%;
    }
}

@media screen and( max-width: $media-breackpoint-up-sm) {
    .includedItem {
        max-width: 300px;
        width: 100%;
        .includedItemHead {
            height: 240px;
            margin: 50px auto 24px;
            position: relative;
            text-align: center;
            .itemHeadImage {
                max-width: 100%;
                .itemHeadImageWrap {
                    text-align: center;
                    img {
                        top: -10px;
                        height: 210px;
                        object-fit: contain;
                        width: 80%;
                    }
                }
            }
            .includedItemLabel {
                top: -40px;
            }
        }
    }
}