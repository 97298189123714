@import '../../scss/index.scss';
.proceso {
    background: #cbdb2a;
    color: #fff;
    margin-bottom: -60px;
    padding-bottom: calc(1vw + 60px);
    position: relative;
    &::before {
        background-color: inherit;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        right: -50%;
        top: -7vw;
        width: 250%;
    }
    .eventIncludedSectionTitleWrapProceso {
        top: -7vw;
        .eventIncludedSectionTitleProceso {
            animation-duration: 120s;
            color: #fff;
            font-family: var(--primaryFont);
            font-size: 2.1875rem;
            font-weight: 900;
            text-transform: uppercase;
        }
        .marqueeTrack {
            animation: marquee 180s linear infinite;
            position: absolute;
            white-space: nowrap;
            will-change: transform;
            span {
                color: #fff;
                font-family: var(--primaryFont);
                font-size: 2.1875rem;
                font-weight: 900;
                text-transform: uppercase;
            }
        }
    }
    .marqueeWrap {
        max-width: 100%;
        padding-top: 18px;
        position: relative;
        width: 100vw;
    }
    .wrapper {
        transform: rotate(6deg);
        max-width: 1400px;
        position: relative;
        margin: 0 auto;
        min-width: 20px;
        margin-top: 80px;
        .includedItems {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 43px;
            gap: 0px;
        }
        @media screen and( max-width: 1001) {
            .includedItems {
                flex-direction: column;
            }
        }
    }
}

.proceso,
.eventIncludedSectionTitleWrap {
    top: -1vw;
    transform: rotate(-6deg);
}


/* @media screen and( max-width: 1050) {
    .proceso {
        padding-bottom: calc(200vw + 60px) !important;
        min-height: 120vw !important;
        margin-bottom: 200px !important;
        .wrapper {
            .includedItems {
                flex-direction: column;
                gap: 20px;
            }
        }
    }
} */